export function sortBlogPosts(posts) {
  const sorted = posts
    .map(({ publishedOn: rawPublishedOn, ...post }) => {
      const publishedOn = new Date(rawPublishedOn);
      return {
        ...post,
        publishedOn,
      };
    })
    .sort((a, b) => b.publishedOn - a.publishedOn);
  return sorted;
}
