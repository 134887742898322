import React from "react";
import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
const BlogList = ({ data, StyleVar }) => {
  // const cate = data.categories.map((value, i) => {
  //   return (
  //     <Link to={`/category/${slugify(value)}`} key={i}>
  //       {value}
  //       {i !== data.categories.length - 1 && ","}
  //     </Link>
  //   )
  // })
  const publishedOn = data.publishedOn.toLocaleDateString("en-GB");
  const { caption, ...posterData } = data.poster;

  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={`/article/${data.handle.current ?? data.handle}`}
            className="image"
          >
            <SanityImage
              {...posterData}
              width={300}
              height={300}
              alt={caption}
              className="w-100"
            />
          </Link>
        </div>
        <div className="content">
          <ul className="rn-meta-list">
            <li>
              <Link to={`/articles/author/${data.author.handle.current}`}>
                {data.author.name}
              </Link>
            </li>
            <li className="separator">/</li>
            <li>{publishedOn}</li>
          </ul>
          <h4 className="title">
            <Link to={`/article/${data.handle.current ?? data.handle}`}>
              {data.title}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
};

const BlogPostList = ({ column, StyleVarProp, posts }) => {
  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {posts.map((item) => (
            <div key={item.id} className={column}>
              <BlogList StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogPostList;
