import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbBanner from "../../components/BreadcrumbBanner";
import BlogPostList from "../../components/blog/BlogPostList";
import { sortBlogPosts } from "./util/sortBlogPosts";

const News = ({ pageContext, data }) => {
  const { posts, tag } = pageContext;
  const sorted = sortBlogPosts(posts);
  return (
    <>
      <SEO title="News and views" />
      <Layout>
        <BreadcrumbBanner
          title={tag ? `${tag.text} Articles` : "Our latest News & Views"}
          rootUrl={tag ? "/articles" : "/"}
          parentUrl={tag ? "News and views" : "Home"}
          currentUrl={tag ? tag.text : "News and views"}
        />
        <div className="main-content">
          {/* Start Blog Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <BlogPostList
                  column="col-lg-4 col-md-6 col-12 mt--30"
                  StyleVarProp="box-card-style-default"
                  posts={sorted}
                />
              </div>
            </div>
          </div>
          {/* End Blog Area  */}
        </div>
      </Layout>
    </>
  );
};
export default News;
